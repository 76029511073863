<template>
	<v-app-bar fixed app color="sidebarBackground" v-if="$vuetify.breakpoint.mdAndDown">
		<v-spacer />
		<template v-if="authenticated">
			<NotificationsWidget v-if="setupInfo.verified.value" />
			<NavbarOptionsWidget />
		</template>
		<template v-else>
			<v-btn text rounded :to="{ name: 'AuthLogin' }">
				{{ $t('auth.login') }}
			</v-btn>
			<v-btn text rounded :to="{ name: 'AuthRegister' }">
				{{ $t('auth.register') }}
			</v-btn>
		</template>
	</v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'Navbar',
	components: {
		NotificationsWidget: () => import('@/components/main/NotificationsWidget.vue'),
		NavbarOptionsWidget: () => import('@/components/main/NavbarOptionsWidget.vue'),
	},
	computed: {
		...mapGetters({
			authenticated: 'auth/authenticated',
			setupInfo: 'user/setupInfo',
		}),
	},
}
</script>
